// ================================================================================================
//     File Name: content.scss
//     Description: Page content level SCSS for different screen size, layout and device.
//     ----------------------------------------------------------------------------------------------
//    Item Name: Item Name: Modern Admin -Angular 11+ Bootstrap 5 Admin Dashboard Template
//    Version: 3.0
//     Author: PIXINVENT
//     Authuseror URL: http://www.themeforest.net//pixinvent
// ================================================================================================

// Contest margin left for expanded & collapsed stats width & margin left
// ================================================================================================
html {
   font-size: $base-font-size;
   height: 100%;
   width: 100%;

   body {
     height: 100%;

     &.fixed-navbar {
       padding-top: $navbar-height;
     }
     &.footer-fixed{
       .app-content{
         &.content{
           margin-bottom: 3rem;
         }
       }
     }

     background-color: $body-bg;
     direction: $body-direction;

     a {
       color: $info;

       &:hover {
         color: darken($info, 10);
       }
     }

 // center layout overlay
 .container{
   &.app-content {
     overflow-x: hidden;

     &.show-overlay {
     .content-overlay {
       z-index: 10;
       opacity: 1;
       }
     }

     .content-overlay {
       position: fixed;
       opacity: 0;
       width: 100%;
       height: 100%;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
       background-color: rgba($black, 0.5);
       cursor: pointer;
       transition: all 0.7s;
       z-index: -1;
     }
   }
 }
     .content {
       padding: 0;
       position: relative;
       transition: 300ms ease all;
       backface-visibility: hidden;
       min-height: calc(100% - 3.2rem);

       &.app-content {
         overflow-x: hidden;

         &.show-overlay {
           .content-overlay {
             z-index: 10;
             opacity: 1;
           }
         }

         .content-overlay {
           position: fixed;
           opacity: 0;
           width: 100%;
           height: 100%;
           top: 0;
           left: 0;
           right: 0;
           bottom: 0;
           background-color: rgba($black, 0.5);
           cursor: pointer;
           transition: all 0.7s;
           z-index: -1;
         }
       }

       .content-wrapper {
         padding: 2.1rem 2rem 0;
         overflow: hidden;
         // padding: 1.8rem 2.2rem 0;

         .content-header-title {
           font-weight: 500;
           letter-spacing: 1px;
           color: $gray-800;
         }

         
       }
     }

     // Line awesome font size
     .la {
       font-size: 1.2rem;
     }

     //Remove left margin for 1 column layout
     &[data-col="1-column"] {
       &:not(.vertical-content-menu) {

         .content,
         .footer {
           margin-left: 0px !important;
         }
       }

       &.horizontal-layout {

         .content,
         .footer {
           margin: 0 auto !important;
         }
       }

       &.vertical-content-menu {
         .content-body {
           margin-left: 0px !important;
         }
       }
     }

     &.boxed-layout {
       padding-left: 0;
       padding-right: 0;
       background-color: $white;

       .app-content {
         background-color: $body-bg;
       }
     }

     &.content-boxed-layout {
       .content {
         width: 80%;
         margin: 0 auto;
         padding-left: 260px;
       }
     }

     &.bg-full-screen-image {
      // background-color: #4dd0e1 !important; old_color
      background-color: #CF4144 !important;
       background-size: cover;
     }

     .pace {
       .pace-progress {
         background: $danger;
       }
     }
   }
 }

 /*
 * Blank page
 */

 .blank-page {
   .content-wrapper {
     padding: 0 !important;

     .flexbox-container {
       display: flex;
       align-items: center;
       min-height: calc(100vh - 3.2rem);
     }
   }
 }

 .app-content.center-layout {
   overflow: hidden;
 }
 // causing issue in mega menu horizontal layout -- //TODO
 .center-layout{
   position: relative;
 }

 // Reduce the content padding for mobile screen
 @include media-breakpoint-down(sm) {
   html {
     body {
       footer {
         text-align: center;
       }
     }
   }
 }

 /*
 * Col 3 layout for detached and general type
 */

 @include media-breakpoint-up(lg) {
   body {

     // Normal sidebar
     .content-right {
       width: calc(100% - #{$sidebar-width});
       float: right;
     }

     .content-left {
       width: calc(100% - #{$sidebar-width});
       float: left;
     }

     // Detached sidebar
     .content-detached {
       width: 100%;

       &.content-right {
         float: right;
         margin-left: -$sidebar-width;

         .content-body {
           margin-left: $sidebar-width + 20;
         }
       }

       &.content-left {
         float: left;
         margin-right: -$sidebar-width;

         .content-body {
           margin-right: $sidebar-width + 20;
         }
       }
     }
   }

   .sidebar-right.sidebar-sticky {
     float: right !important;
     margin-left: -$sidebar-width;
     width: $sidebar-width !important;
   }

   //Detached left sticky sidebar
   .sidebar-left.sidebar-sticky {
     float: left !important;
     margin-right: -$sidebar-width;
     width: $sidebar-width !important;
   }


   //left sticky sidebar
   [data-col="content-left-sidebar"] {
     .sticky-wrapper {
       float: left;
     }
   }

   .vertical-content-menu {
     .content {

       // Detached sidebar
       .sidebar-left {
         margin-left: ($content-menu-expanded-width + 28);
       }

       .content-detached {
         &.content-right {
           float: right;
           width: calc(100% - #{$sidebar-width});
           margin-left: -$sidebar-width;

           .content-body {
             margin-left: ($sidebar-width - 5);
           }
         }

         &.content-left {
           float: left;
           width: 100%;
           margin-right: -$sidebar-width;

           .content-body {
             margin-right: $sidebar-width + 20;
           }
         }
       }
     }
   }
 }
