// ================================================================================================
// 	File Name: chat-application.scss
// 	Description: SCC file for chat application page.
// 	----------------------------------------------------------------------------------------------
// 	Item Name: Modern Admin - Clean Bootstrap 5 Dashboard HTML Template
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================
// Core variables and mixins
@import "../bootstrap/functions";
@import "../bootstrap/mixins";
@import "../bootstrap-extended/mixins";
// Load variable overrides
@import "../core/variables/variables";
@import "../bootstrap/variables";
@import "../core/variables/components-variables";

/* chat application css */


/*----------------------*/

// app chat wrapper
.chat-application {
    // app chat content wrapper
    .app-content .content-right .content-wrapper {
        padding: 0; // override default padding
    }
    .app-content {
        border: 1px solid $border-color;
        border-radius: $border-radius-sm;
    }
    .chat-user-profile {
        /* app chat user profile sidebar */
        position: absolute;
        height: calc(100vh - 8.4rem);
        width: $chat-sidebar-width;
        border-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        background-color: $white;
        transform: translateX(-112%);
        transition: all 0.3s ease;
        z-index: 6;
        &.show {
            transform: translateX(0);
        }
        // close icon positioning
        .chat-user-profile-header {
            .chat-profile-close {
                position: absolute;
                top: 14px;
                right: 13px;
                cursor: pointer;
            }
        }
        // user profile scrollbar
        .chat-user-profile-content {
            .chat-user-profile-scroll {
                position: relative;
                height: calc(100vh - 22.5rem);
                padding: 2rem;
            }
        }
    }
    // chat sidebar content
    .chat-sidebar {
        height: calc(100vh - 8.4rem);
        position: absolute;
        width: $chat-sidebar-width;
        border-right: 1px solid $border-color;
        border-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin: 0;
        box-shadow: none;
        // chat close icon
        .chat-sidebar-close {
            position: absolute;
            right: 0.25rem;
            top: 0.25rem;
            font-size: 1.25rem;
            z-index: 1;
            cursor: pointer;
            visibility: hidden;
        }
        .chat-sidebar-search {
            /* chat search area */
            position: absolute;
            padding: 0.65rem 1rem;
            border-bottom: 1px solid $border-color;
            width: $chat-sidebar-width;
            // search box model
            input.form-control {
                height: calc(1.2em + 1.2rem);
                margin-top: -1px;
                padding-left: calc(1.25em + 1rem + 2px);
            }
            // search icon positioning
            .form-control-position {
                top: -5px;
                i {
                    position: relative;
                    left: 2px;
                    top: 0;
                }
            }
        }
        // chat sidebar list
        .chat-sidebar-list-wrapper {
            position: relative;
            height: calc(100% - 4rem);
            width: $chat-sidebar-width;
            margin-top: 3.7rem;
            ul {
                padding-left: 0;
                margin-bottom: 0;
            }
            li {
                padding: 0.8rem 1.5rem;
                &:hover {
                    cursor: pointer;
                    background: $hover-color;
                }
                &.active {
                    background: $primary;
                    h6,
                    span {
                        color: $white !important;
                    }
                }
                img {
                    border: 2px solid $white;
                }
            }
        }
    }
    .chat-overlay {
        /* chat overlay */
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        display: block;
        border-radius: $border-radius-sm;
        visibility: hidden;
        &.show {
            background-color: rgba($black, 0.2);
            visibility: visible;
        }
    }
    // app chat window
    .chat-window-wrapper {
        .chat-container {
            position: relative;
            height: calc(100vh - 17.5rem);
            text-align: center;
            padding: 1.4rem;
        }
        // chat background image
        .chat-start,
        .chat-container {
            background-color: #DFDBE5;
            background-image: url("data:image/svg+xml,%3Csvg width='180' height='180' viewBox='0 0 180 180' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M81.28 88H68.413l19.298 19.298L81.28 88zm2.107 0h13.226L90 107.838 83.387 88zm15.334 0h12.866l-19.298 19.298L98.72 88zm-32.927-2.207L73.586 78h32.827l.5.5 7.294 7.293L115.414 87l-24.707 24.707-.707.707L64.586 87l1.207-1.207zm2.62.207L74 80.414 79.586 86H68.414zm16 0L90 80.414 95.586 86H84.414zm16 0L106 80.414 111.586 86h-11.172zm-8-6h11.173L98 85.586 92.414 80zM82 85.586L87.586 80H76.414L82 85.586zM17.414 0L.707 16.707 0 17.414V0h17.414zM4.28 0L0 12.838V0h4.28zm10.306 0L2.288 12.298 6.388 0h8.198zM180 17.414L162.586 0H180v17.414zM165.414 0l12.298 12.298L173.612 0h-8.198zM180 12.838L175.72 0H180v12.838zM0 163h16.413l.5.5 7.294 7.293L25.414 172l-8 8H0v-17zm0 10h6.613l-2.334 7H0v-7zm14.586 7l7-7H8.72l-2.333 7h8.2zM0 165.414L5.586 171H0v-5.586zM10.414 171L16 165.414 21.586 171H10.414zm-8-6h11.172L8 170.586 2.414 165zM180 163h-16.413l-7.794 7.793-1.207 1.207 8 8H180v-17zm-14.586 17l-7-7h12.865l2.333 7h-8.2zM180 173h-6.613l2.334 7H180v-7zm-21.586-2l5.586-5.586 5.586 5.586h-11.172zM180 165.414L174.414 171H180v-5.586zm-8 5.172l5.586-5.586h-11.172l5.586 5.586zM152.933 25.653l1.414 1.414-33.94 33.942-1.416-1.416 33.943-33.94zm1.414 127.28l-1.414 1.414-33.942-33.94 1.416-1.416 33.94 33.943zm-127.28 1.414l-1.414-1.414 33.94-33.942 1.416 1.416-33.943 33.94zm-1.414-127.28l1.414-1.414 33.942 33.94-1.416 1.416-33.94-33.943zM0 85c2.21 0 4 1.79 4 4s-1.79 4-4 4v-8zm180 0c-2.21 0-4 1.79-4 4s1.79 4 4 4v-8zM94 0c0 2.21-1.79 4-4 4s-4-1.79-4-4h8zm0 180c0-2.21-1.79-4-4-4s-4 1.79-4 4h8z' fill='%239C92AC' fill-opacity='0.1' fill-rule='evenodd'/%3E%3C/svg%3E");
        }
        .chat-start {
            height: calc(100vh - 8.4rem);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .chat-start-icon,
            .chat-start-text {
                box-shadow: 0 4px 8px 0 rgba($black, 0.12), 0 2px 4px 0 rgba($black, 0.08) !important;
                cursor: pointer;
            }
            .chat-start-text {
                border-radius: calc(#{$border-radius} * 5);
            }
            .chat-start-icon {
                border-radius: 50%;
                background-color: $white;
            }
        }
    }
    .chat-content {
        .chat-body {
            overflow: hidden;
            margin: 0.67rem 2rem 0 0;
            .chat-message {
                position: relative;
                float: right;
                text-align: right;
                padding: 0.75rem 1rem;
                margin: 0 1.33rem 1.33rem 0.3rem;
                clear: both;
                word-break: break-word;
                color: $white;
                background: $primary;
                border-radius: $border-radius;
                box-shadow: 0 2px 4px 0 rgba($primary, 0.6);
                p {
                    margin-bottom: 0;
                }
                // chat time positioning & typography
                .chat-time {
                    position: absolute;
                    bottom: -20px;
                    right: 0px;
                    color: $text-muted;
                    font-size: 0.8rem;
                    white-space: nowrap;
                }
                .chat-time1 {
                    position: absolute;
                    bottom: -20px;
                    left: 0px;
                    color: $text-muted;
                    font-size: 0.8rem;
                    white-space: nowrap;
                }
            }
        }
        // chat avatar right
        .chat-avatar {
            float: right;
        }
        // chat left
        .chat-left {
            .chat-avatar {
                float: left;
            }
            .chat-message {
                text-align: left;
                float: left;
                margin: 0 0.3rem 1.8rem 1.3rem;
                color: $body-color;
                background-color: $app-chat-message-bg;
                box-shadow: 0 2px 6px 0 rgba($black, 0.3);
            }
        }
        .badge {
            background-color: $chat-badge-bg;
            color: $body-color;
        }
    }
    .chat-profile {
        /* chat profile right sidebar */
        height: calc(100vh - 8.5rem);
        width: $chat-sidebar-width;
        border-radius: 0.25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-color: $white;
        // position: fixed;
        position: absolute;
        transform: translateX(110%);
        transition: all 0.3s ease;
        z-index: 3;
        right: 1.8rem;
        bottom: 0;
        opacity: 0;
        &.show {
            opacity: 1;
            transform: translateX(8%);
        }
        // user profile header
        .chat-profile-header {
            .chat-profile-close {
                position: absolute;
                top: 14px;
                right: 13px;
                cursor: pointer;
            }
        }
        .chat-profile-content {
            position: relative;
            height: calc(100vh - 22.8rem);
        }
    }
    img {
        &.round {
            border-radius: 3.65rem;
        }
    }
}

// horizontal layout
.chat-application {
    &.horizontal-layout {
        &.horizontal-menu-padding {
            .app-content {
                min-height: calc(100% - 15rem);
                .content-header {
                    margin-bottom: 0;
                }
            }
            .chat-user-profile {
                height: calc(100vh - 15rem);
                width: 315px;
                // user profile scrollbar
                .chat-user-profile-content {
                    .chat-user-profile-scroll {
                        height: calc(100vh - 29.1rem);
                    }
                }
                &.show {
                    visibility: visible;
                }
            }
            // chat sidebar content
            .chat-sidebar {
                height: calc(100vh - 15rem);
                // chat sidebar list
                .chat-sidebar-list-wrapper {
                    height: calc(100% - 3.7rem);
                }
            }
            // app chat window
            .chat-window-wrapper {
                .chat-container {
                    height: calc(100vh - 24.1rem);
                }
                .chat-start {
                    height: calc(100vh - 15rem);
                }
            }
            .chat-profile {
                /* chat profile right sidebar */
                height: calc(100vh - 15rem);
                .chat-profile-content {
                    height: calc(100vh - 33.3rem);
                }
            }
        }
    }
}

// horizontal nav layout
.chat-application {
    &.horizontal-layout {
        .app-content {
            padding: 0;
            min-height: calc(100% - 13.4rem);
        }
        .chat-user-profile {
            height: calc(100vh - 13.6rem);
            // transform: translateX(-167%);
            // user profile scrollbar
            .chat-user-profile-content {
                .chat-user-profile-scroll {
                    height: calc(100vh - 27.7rem);
                }
            }
        }
        // chat sidebar content
        .chat-sidebar {
            height: calc(100vh - 13.6rem);
            // chat sidebar list
            .chat-sidebar-list-wrapper {
                height: calc(100% - 3.8rem);
            }
        }
        // app chat window
        .chat-window-wrapper {
            .chat-container {
                height: calc(100vh - 22.6rem);
            }
            .chat-start {
                height: calc(100vh - 13.6rem);
            }
        }
        .chat-profile {
            /* chat profile right sidebar */
            height: calc(100vh - 13.7rem);
            .chat-profile-content {
                height: calc(100vh - 27.8rem);
            }
        }
    }
}

// Media query for large screen
@media (min-width: 768px) and (max-width: 992px) {
    .content-right {
        width: 100%;
    }
    .chat-application {
        .chat-sidebar {
            transform: translateX(-110%);
            transition: all 0.3s ease-in-out;
            z-index: 5;
            left: 23px;
            &.show {
                transform: translateX(-7.2%);
            }
            .chat-sidebar-close {
                visibility: visible !important;
            }
        }
    }
}

@media (max-width: 1200px) {
    .chat-application {
        &.horizontal-layout {
            .app-content {
                padding: 0;
                min-height: calc(100% - 13.4rem);
            }
            .chat-user-profile {
                height: calc(100vh - 8.3rem);
                // user profile scrollbar
                .chat-user-profile-content {
                    .chat-user-profile-scroll {
                        height: calc(100vh - 22.4rem);
                    }
                }
            }
            // chat sidebar content
            .chat-sidebar {
                height: calc(100vh - 8.3rem);
                // chat sidebar list
                .chat-sidebar-list-wrapper {
                    height: calc(100% - 3.8rem);
                }
            }
            // app chat window
            .chat-window-wrapper {
                .chat-container {
                    height: calc(100vh - 17.3rem);
                }
                .chat-start {
                    height: calc(100vh - 8.3rem);
                }
            }
            .chat-profile {
                /* chat profile right sidebar */
                height: calc(100vh - 8.4rem);
                .chat-profile-content {
                    height: calc(100vh - 22.5rem);
                }
            }
        }
    }
    // horizontal layout
    .chat-application {
        &.horizontal-layout {
            &.horizontal-menu-padding {
                .app-content {
                    padding: 0;
                }
                .chat-user-profile {
                    height: calc(100vh - 10rem);
                    // user profile scrollbar
                    .chat-user-profile-content {
                        .chat-user-profile-scroll {
                            height: calc(100vh - 24.1rem);
                        }
                    }
                }
                // chat sidebar content
                .chat-sidebar {
                    height: calc(100vh - 9.9rem);
                    // chat sidebar list
                    .chat-sidebar-list-wrapper {
                        height: calc(100% - 3.7rem);
                    }
                }
                // app chat window
                .chat-window-wrapper {
                    .chat-container {
                        height: calc(100vh - 19rem);
                    }
                    .chat-start {
                        height: calc(100vh - 9.8rem);
                    }
                }
                .chat-profile {
                    /* chat profile right sidebar */
                    height: calc(100vh - 10rem);
                    .chat-profile-content {
                        height: calc(100vh - 24.1rem);
                    }
                }
            }
        }
    }
}

// Media query for medium screen
@media (min-width: 576px) and (max-width: 768px) {
    .content-right {
        width: 100%;
    }
    .chat-application {
        .chat-sidebar {
            transform: translateX(-110%);
            transition: all 0.3s ease-in-out;
            z-index: 5;
            left: -35px;
            &.show {
                transform: translateX(12.4%);
            }
            .chat-sidebar-close {
                visibility: visible !important;
            }
        }
    }
}

// Media query for extra small screen
@media (max-width: 349.98px) {
    .chat-application {
        .chat-sidebar {
            transform: translateX(-110%);
            transition: all 0.3s ease-in-out;
            z-index: 5;
            width: 230px !important;
            left: -27px !important;
            .chat-sidebar-search,
            .chat-sidebar-list-wrapper {
                width: 230px !important;
            }
            &.show {
                transform: translateX(12.4%);
            }
            .chat-sidebar-close {
                visibility: visible !important;
            }
        }
        .chat-user-profile {
            width: 230px !important;
        }
        .chat-profile {
            width: 230px !important;
            right: 1.35rem !important;
        }
    }
}

// Media query for extra small screen
@include media-breakpoint-down(xs) {
    .chat-sidebar {
        transform: translateX(-110%);
        transition: all 0.3s ease-in-out;
        z-index: 5;
        width: $menu-expanded-width !important;
        left: -31px !important;
        .chat-sidebar-search,
        .chat-sidebar-list-wrapper {
            width: $menu-expanded-width !important;
        }
        &.show {
            transform: translateX(12.4%);
        }
        .chat-sidebar-close {
            visibility: visible !important;
        }
    }
    .chat-user-profile {
        width: $menu-expanded-width !important;
    }
    .chat-profile {
        width: $menu-expanded-width !important;
        right: 1.6rem !important;
    }
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    /* IE specifc CSS */
    .chat-sidebar-list-wrapper {
        .chat-sidebar-list {
            .d-flex {
                display: inline-flex !important;
            }
        }
    }
}

@supports (-ms-ime-align: auto) {
    /* Edge specific css */
    .chat-sidebar-list-wrapper {
        .chat-sidebar-list {
            .d-flex {
                display: inline-flex !important;
            }
        }
    }
}